import axios from './Axios';
import { getConfig } from '../../AppConfig';

const path = '/gift-certificate';
const config = getConfig();

export const getGCTemplates = () =>
  axios.get(`${path}/templates/all`, {
    params: {
      client: config.profileKey,
    },
  });

export const purchaseGiftCertificates = (data) =>
  axios.post(`${path}/buy`, {
    ...data,
    client: config.profileKey,
  });

export const redeemGiftCertificates = (code) =>
  axios.post(`${path}/redeem`, { code });

export const getGCAvailable = (checkoutCode) =>
  axios.get(`${path}/available-balance/${checkoutCode}`);

export const getCheckoutData = (checkoutCode) =>
  axios.get(`${path}/order/tracking/${checkoutCode}`);

export const getGCActivity = (params) =>
  axios.get(`${path}/activity`, {
    params: {
      ...params,
      client: config.profileKey,
    },
  });

export const getGCPurchased = (params) =>
  axios.get(`${path}/purchased`, {
    params: {
      ...params,
      client: config.profileKey,
    },
  });

export const resendGiftCertificates = (data) =>
  axios.post(`${path}/resend-email`, data);

export const getGCAvailableForClient = () =>
  axios.get(`${path}/user/all-available-balances`);

export default {
  getGCTemplates,
  purchaseGiftCertificates,
  getCheckoutData,
  getGCActivity,
  getGCPurchased,
  resendGiftCertificates,
  getGCAvailableForClient,
};
