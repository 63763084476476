import leaveDoor from '../assets/images/leaveDoor.png';
import meetOutside from '../assets/images/meetOutside.png';
import meetAtDoor from '../assets/images/meetAtDoor.png';
import giftCardImageOne from '../assets/images/giftCardImageOne.png';
import giftCardImageThree from '../assets/images/giftCardImageThree.png';

export const routeNames = {
  home: '/',
  notfound: '/notfound',
  profile: '/profile',
  checkout: '/checkout',
  restaurants: '/restaurants',
  termsAndConditions: '/terms-of-use',
  restaurant: '/restaurant/:id',
  trackOrder: '/track-orders/:id',
  resetPassword: '/resetpassword',
  orderSuccess: '/order-success/:id/:restaurantId',
  accountDetails: '/account-details/:tab',
  accountDetailsAccount: '/account-details/account',
  accountDetailsAddresses: '/account-details/addresses',
  accountDetailsOrderHistory: '/account-details/order-history',
  giftCards: '/gift-cards',
  purchaseGift: '/purchase-gift',
  giftOrderConfirmation: '/gift-order-confirmation',
  giftCardEmail: '/gift-card-email',
  emailAccount: '/email-account',

  /* Restaurant menu and order router */
  restaurantMenu: '/menu',
  restaurantOrder: '/order',
  contact: '/contact',
  aboutus: '/aboutus',
};

export const filterRestaurantTypePage = {
  list: 'list',
  map: 'map',
};

export const sortOptions = [
  {
    testId: 'name',
    text: 'A-Z',
    target: 'name',
  },
  {
    testId: 'distance',
    text: 'Nearest',
    target: 'location',
    type: 'takeout',
  },
  {
    testId: 'fee',
    text: 'Fee',
    target: 'fees',
  },
  {
    testId: 'orderType',
    text: 'Soonest',
    target: 'delays',
    type: 'delivery',
  },
];

export const orderStatuses = {
  open: 'open',
  confirmed: 'confirmed',
  driving: 'driving',
  canceled: 'canceled',
  completed: 'completed',
  Completed: 'Completed',
  Progress: 'In Progress',
};

export const TITLE_PAYMENT = 'Payment';

export const DELIVERY_OPTIONS = [
  { value: 'meet_at_door', label: 'Meet at Door', image: meetAtDoor },
  { value: 'leave_at_door', label: 'Leave at Door', image: leaveDoor },
  { value: 'meet_outside', label: 'Meet outside', image: meetOutside },
];

export const TAKEOUT = 'Takeout';
export const DELIVERY = 'Delivery';

export const EMOJI_UNI_CODE = '0x1F389';

export const notificationTypes = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const doAppearIn = [
  routeNames.checkout,
  routeNames.orderSuccess,
  routeNames.restaurants,
  routeNames.accountDetails,
  routeNames.home,
  routeNames.termsAndConditions,
  routeNames.purchaseGift,
  routeNames.giftOrderConfirmation,
];

export const MIN_SHOW_RATING_REVIEWS = 15;
export const TRACK_ORDER_API_CALL_INTERVAL = 60 * 1000;
export const IDLE_TIMEOUT_IN_SECONDS = 2 * 60 * 60 * 1000;

export const RATING_FORM_ID = 1;

export const giftQuestion = [
  {
    question: 'How do I redeem an Shoreline Menu gift card?',
    answer:
      'Curabitur quis semper dui. Nunc scelerisque neque in lacus facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam vel orci nec massa ullamcorper dictum.\n',
  },
  {
    question: 'Do the gift cards expire?',
    answer:
      'Curabitur quis semper dui. Nunc scelerisque neque in lacus facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam vel orci nec massa ullamcorper dictum.\n',
  },
  {
    question: 'Can I change the amount after the gift card is sent?',
    answer:
      'Curabitur quis semper dui. Nunc scelerisque neque in lacus facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam vel orci nec massa ullamcorper dictum.\n',
  },
  {
    question: 'Do you offer physical gift cards?',
    answer:
      'Curabitur quis semper dui. Nunc scelerisque neque in lacus facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam vel orci nec massa ullamcorper dictum.\n',
  },
  {
    question: 'I never received the gift card.',
    answer:
      'Curabitur quis semper dui. Nunc scelerisque neque in lacus facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam vel orci nec massa ullamcorper dictum.\n',
  },
  {
    question: 'How do I resend a gift card?',
    answer:
      'Curabitur quis semper dui. Nunc scelerisque neque in lacus facilisis, at pulvinar nisi dictum. Proin vel rhoncus ipsum. Nam vel orci nec massa ullamcorper dictum.\n',
  },
];

export const giftCardDetail = [
  {
    To: 'Tony Stark (tonystark@starktowers.com)',
    From: 'Jane Andrews (janeandrews@gmail.com)',
    DeliveryDate: '9/15/2022',
    message: 'Happy Birthday',
    cardCode: 'XXXX-XXXX-XXXX-XXXX',
    image: giftCardImageOne,
  },
  {
    To: 'Tony Stark (tonystark@starktowers.com)',
    From: 'Jane Andrews (janeandrews@gmail.com)',
    DeliveryDate: '9/15/2022',
    message: 'Happy Birthday',
    cardCode: 'XXXX-XXXX-XXXX-XXXX',
    image: giftCardImageThree,
  },
  {
    To: 'Tony Stark (tonystark@starktowers.com)',
    From: 'Jane Andrews (janeandrews@gmail.com)',
    DeliveryDate: '9/15/2022',
    message: 'Happy Birthday',
    cardCode: 'XXXX-XXXX-XXXX-XXXX',
    image: giftCardImageOne,
  },
  {
    To: 'Tony Stark (tonystark@starktowers.com)',
    From: 'Jane Andrews (janeandrews@gmail.com)',
    DeliveryDate: '9/15/2022',
    message: 'Happy Birthday',
    cardCode: 'XXXX-XXXX-XXXX-XXXX',
    image: giftCardImageThree,
  },
];

export const giftActivityType = {
  payment: 'order_menu_checkout',
  redemption: 'redeem_gift_certificate',
};

export const giftActivityTabMap = {
  viewall: {
    id: 'viewall',
    label: 'View All',
    statusList: [],
  },
  payment: {
    id: 'payment',
    label: 'Payment',
    statusList: [giftActivityType.payment],
  },
  redemption: {
    id: 'redemption',
    label: 'Redemption',
    statusList: [giftActivityType.redemption],
  },
};
