import { put, call, takeLeading } from 'redux-saga/effects';
import Bugsnag from '@bugsnag/js';

import {
  getGCTemplates,
  purchaseGiftCertificates,
  getCheckoutData,
  redeemGiftCertificates,
  getGCAvailable,
  getGCActivity,
  getGCPurchased,
  resendGiftCertificates,
  getGCAvailableForClient,
} from '../../../../../util/api/GiftApiClient';
import Notification from '../../../../../components/layout/Notification';
import {
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplatesFail,
  purchaseGiftCertificatesRequest,
  purchaseGiftCertificatesSuccess,
  purchaseGiftCertificatesFail,
  getCheckoutDataRequest,
  getCheckoutDataSuccess,
  getCheckoutDataFail,
  redeemGCRequest,
  redeemGCSuccess,
  redeemGCFail,
  getGCAvailableSuccess,
  getGCAvailableFail,
  getGCAvailableRequest,
  setGCAvailableTrigger,
  getGCActivityRequest,
  getGCActivitySuccess,
  getGCActivityFail,
  getGCPurchasedRequest,
  getGCPurchasedSuccess,
  getGCPurchasedFail,
  resendGCSuccess,
  resendGCFail,
  resendGCRequest,
  getBalanceSuccess,
  getBalanceFail,
  getBalanceRequest,
} from '../../../reducers/ducks/legacy/GiftDuck';

export function* getTemplatesSaga() {
  try {
    const templates = yield call(getGCTemplates);

    yield put(getTemplatesSuccess(templates));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getTemplatesFail());
    const message =
      `GC Templates: ${error.data?.message}` ||
      'Failed to get Gift Certificate templates';
    Notification.error(message, { toastId: 'gc-templates-error' });
  }
}

export function* purchaseGiftCertificatesSaga({ payload }) {
  try {
    const response = yield call(purchaseGiftCertificates, payload);

    yield put(purchaseGiftCertificatesSuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(purchaseGiftCertificatesFail());
    const message =
      `GC Buy: ${error.data?.message}` || 'Failed to buy Gift Certificates';
    Notification.error(message, { toastId: 'gc-buy-error' });
  }
}

export function* getCheckoutDataSaga({ payload }) {
  try {
    const response = yield call(getCheckoutData, payload);

    yield put(getCheckoutDataSuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getCheckoutDataFail());
    const message =
      `GC Checkout Data: ${error.data?.message}` ||
      'Failed to get Gift Certificate checkout data';
    Notification.error(message, { toastId: 'gc-checkout-data-error' });
  }
}

export function* redeemGCSaga({ payload }) {
  try {
    const response = yield call(redeemGiftCertificates, payload);

    yield put(redeemGCSuccess(response));
    Notification.success('Gift Certificates redeemed successfully', {
      toastId: 'gc-redeem-success',
    });
    yield put(setGCAvailableTrigger(true));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(redeemGCFail());
    const message =
      `GC Redeem: ${error.data?.message}` ||
      'Failed to redeem Gift Certificates';
    Notification.error(message, { toastId: 'gc-redeem-error' });
  }
}

export function* getGCAvailableSaga({ payload }) {
  try {
    const response = yield call(getGCAvailable, payload);

    yield put(getGCAvailableSuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getGCAvailableFail());
    const message =
      `GC Available: ${error.data?.message}` ||
      'Failed to get Gift Certificates available';
    Notification.error(message, { toastId: 'gc-available-error' });
  }
}

export function* getGCActivitySaga({ payload }) {
  try {
    const response = yield call(getGCActivity, payload);

    yield put(getGCActivitySuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getGCActivityFail());
    const message =
      `GC Activity: ${error.data?.message}` ||
      'Failed to get Gift Certificate activity';
    Notification.error(message, { toastId: 'gc-activity-error' });
  }
}

export function* getGCPurchasedSaga({ payload }) {
  try {
    const response = yield call(getGCPurchased, payload);

    yield put(getGCPurchasedSuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getGCPurchasedFail());
    const message =
      `GC Purchased: ${error.data?.message}` ||
      'Failed to get Gift Certificate purchased';
    Notification.error(message, { toastId: 'gc-purchased-error' });
  }
}

export function* resendGCSaga({ payload }) {
  try {
    const response = yield call(resendGiftCertificates, payload);

    yield put(resendGCSuccess(response));
    Notification.success('Gift Certificates resent successfully', {
      toastId: 'gc-resend-success',
    });
  } catch (error) {
    Bugsnag.notify(error);
    yield put(resendGCFail());
    const message =
      `GC Resend: ${error.data?.message}` ||
      'Failed to resend Gift Certificates';
    Notification.error(message, { toastId: 'gc-resend-error' });
  }
}

export function* getGCBalanceSaga() {
  try {
    const response = yield call(getGCAvailableForClient);

    yield put(getBalanceSuccess(response));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(getBalanceFail());
    const message =
      `GC Balance: ${error.data?.message}` ||
      'Failed to get Gift Certificate balance';
    Notification.error(message, { toastId: 'gc-balance-error' });
  }
}

export function* watchGiftSagas() {
  yield takeLeading(getTemplatesRequest.type, getTemplatesSaga);
  yield takeLeading(
    purchaseGiftCertificatesRequest.type,
    purchaseGiftCertificatesSaga
  );
  yield takeLeading(getCheckoutDataRequest.type, getCheckoutDataSaga);
  yield takeLeading(redeemGCRequest.type, redeemGCSaga);
  yield takeLeading(getGCAvailableRequest.type, getGCAvailableSaga);
  yield takeLeading(getGCActivityRequest.type, getGCActivitySaga);
  yield takeLeading(getGCPurchasedRequest.type, getGCPurchasedSaga);
  yield takeLeading(resendGCRequest.type, resendGCSaga);
  yield takeLeading(getBalanceRequest.type, getGCBalanceSaga);
}
