import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedAddress } from '../redux/legacy/reducers/ducks/new/Address/Address.types';
import { setSelectedAddress as storeSelectedAddress } from '../redux/legacy/reducers/ducks/new/Address/AddressReducer';
import { selectSelectedAddress } from '../redux/legacy/reducers/ducks/new/Address/AddressSelector';

export function useWhere() {
  const dispatch = useDispatch();
  const storedAddress = useSelector(selectSelectedAddress);
  const [dAddress, setDAddress] = useState(null);

  const setSelectedAddress = (address: SelectedAddress | null) =>
    dispatch(storeSelectedAddress(address));

  useEffect(() => {
    const deliverAddressString = localStorage.getItem('deliver_address');
    let deliverAddress = null;
    if (deliverAddressString) {
      deliverAddress = JSON.parse(deliverAddressString);
    }
    if (deliverAddress) {
      if (storedAddress?.entered_address !== deliverAddress?.entered_address) {
        setSelectedAddress(deliverAddress);
      }
      setDAddress(deliverAddress);
    }
  }, []);

  const selectedAddress = useMemo(
    () =>
      // This should check if the logged in user is the same, if not reset the address user data and update
      // TODO
      storedAddress,
    [storedAddress]
  );

  return {
    dAddress,
    selectedAddress,
    setSelectedAddress,
  };
}

export default useWhere;
